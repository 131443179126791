import React from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next'
import {
  Button, Form, FormGroup, Label, Input, FormText,
} from 'reactstrap';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';
import Blog01 from '../images/blog/blog.jpg';
import Layout from '../layouts/index';
import BlogArticle from '../components/Blog';
import Clients from '../components/Clients';
import ProductCLM1 from '../images/product-clm-1.png';
import Lawyer from '../images/lawyer.jpg';
import DemoForm from '../components/DemoForm';
import MujerStarter from '../images/mujer-starter.jpg';
import ClmHero from '../images/trato-clm-hero.png';
import FreelanceHero from '../images/freelance-hero.png';
import ctaImage from '../images/cta-image.png';

import Hero from '../images/hero.png';


function Freelance({ pageContext: { locale }, location}) {
    const { t } = useTranslation()
  return (
    <Layout id="landing" locale={locale} bodyClass="page-home landing" inverted border location={location}>

      <section className="bg-grey">
        <div className="container mt-5">
          <div className="row d-flex">
            <div className="col-lg-6 align-self-center">
              <h1 className="mb-2">{t('freelance.title1')} <span className="font-weight-bold underline-primary">{t('freelance.title2')} </span></h1>
              <p className="text-muted mb-3 lead">{t('freelance.subtitle1')}
              </p>
              <DemoForm type="planFreelance" />
            </div>
            <div className="col-lg-6">
              <img src={FreelanceHero} className="img-fluid" />
            </div>
          </div>
        </div>
      </section>
      <section className="bg-light">
        <div className="container">
          <div className="row d-flex my-5">
            <div className="col-lg-6">
              <img src={ProductCLM1} className="img-fluid" />
            </div>
            <div className="col-lg-6 align-self-center">
              <p className="lead mb-3">{t('freelance.subtitle2')} </p>
              <p className="font-weight-bold">{t('freelance.text')} </p>
              <ul className="list-unstyled">
                <li className="text-muted mb-1"><FontAwesomeIcon icon={faCheckCircle} className="text-primary align-baseline icon-sm mr-1" />{t('freelance.feature1')} </li>
                <li className="text-muted mb-1"><FontAwesomeIcon icon={faCheckCircle} className="text-primary align-baseline icon-sm mr-1" />{t('freelance.feature2')} </li>
                <li className="text-muted mb-1"><FontAwesomeIcon icon={faCheckCircle} className="text-primary align-baseline icon-sm mr-1" />{t('freelance.feature3')}</li>
                <li className="text-muted mb-1"><FontAwesomeIcon icon={faCheckCircle} className="text-primary align-baseline icon-sm mr-1" />{t('freelance.feature4')} </li>
                <li className="text-muted"><FontAwesomeIcon icon={faCheckCircle} className="text-primary align-baseline icon-sm mr-1" />{t('freelance.feature5')}</li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-grey">
        <div className="container">
          <div className="card bg-light rounded shadow-sm mt-5">
            <div className="card-body">
              <div className="row d-flex">
                <div className="col-lg-8 d-flex py-2 px-4">
                  <div className="align-self-center">
                    <h3>{t('freelance.cta1')} <span className="font-weight-bold underline-primary">{t('freelance.cta2')}</span></h3>
                    <p className="lead text-muted">{t('legalAdvisor.ctaText')}</p>
                    <DemoForm type="legal-advisor" />
                  </div>
                </div>
                <div className="col-lg-4 d-none d-lg-block">
                  <img src={ctaImage} className="img-fluid rounded-right" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    </Layout>
  );
}

export default Freelance;
